<template>
  <div>
      <div class="content">
        <span>标题</span><i class="el-icon-arrow-right"></i><span>{{$route.query.title}}</span>
        <i class="fenge"></i>
        <span>来源</span><i class="el-icon-arrow-right"></i><span>{{$route.query.flag == 1?'员工':'系统'}}</span>
      </div>
    <div class="container">

      <!-- 功能区 -->
    
      <div class="header-item">
        <el-input  placeholder="请输入员工姓名" v-model="query.name" clearable @clear="getList(0)"  suffix-icon="el-icon-search">
        </el-input>
      </div>
      <div class="header-item">
        <el-button type="primary" icon="el-icon-search" @click="getList(0)">搜索</el-button>
      </div>
      <!-- 列表区 -->
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 10px"
        header-row-class-name="table-header-style"
        :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}"
        >
        <!-- 员工姓名 -->
        <el-table-column label="员工姓名" >
          <template slot-scope="scope">
            <p style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{ scope.row.name || "无" }}</p>
          </template>
        </el-table-column>
        <!-- 所属部门 -->
        <el-table-column  label="所属部门">
          <template slot-scope="scope">
            <p>{{scope.row.teamName || "无"}}</p>
          </template>
        </el-table-column>
        <!-- 线索数 -->
        <el-table-column label="线索数" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.share || 0 }}</p>
          </template>
        </el-table-column>
        <!-- 分享明细 -->
        <el-table-column label="操作" width="94">
          <template slot-scope="scope">
            <el-button @click="ViewCustomer(scope.row)" type="text" size="small">查看客户数据</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页列表 -->
      <div style="float: right; margin-top: 10px">
        <el-pagination
          @current-change="getList"
          :current-page="query.pageIndex"
          :page-size="query.pageNumber"
          background
          layout="total, prev, pager, next, jumper"
          :total="total_rows">
        </el-pagination>
      </div>
      <!-- 客户数据 -->
      <el-dialog title="客户数据" :visible.sync="dialogVisible" width="1200px" :close-on-press-escape="false"
        :close-on-click-modal="false"
        lock-scroll modal-append-to-body :append-to-body="true" class="share-dialog" style="overflow: hidden">
        <div class="content">
          <div class="heard_img">
            <img :src="staffInfo.headImg || '无'" alt="" > 
          </div>
          <div class="content-item">
            <span>{{staffInfo.position}}</span><i style="margin:0 10px;">/</i><span >{{staffInfo.name}} </span>
            <div><label >分享文章</label><i style="margin:0 10px;" >/</i><span>{{$route.query.title}}</span></div>
          </div>
        </div> 
        <el-table class="dialog"
          ref="multipleTable"
          :data="customerList"
          tooltip-effect="dark"
          style="width: 100%; margin-top: 10px"
          header-row-class-name="table-header-style"
          :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}"
         >
          <!-- 客户头像 -->
          <el-table-column label="头像" >
            <template slot-scope="scope">
              <div class="heard_img">
                <img :src="scope.row.avatar" alt="">
              </div>
            </template>
          </el-table-column>
          <!-- 客户昵称 -->
          <el-table-column label="客户昵称" >
            <template slot-scope="scope">
             <p>{{scope.row.nickname || '微信昵称'}}</p>
            </template>
          </el-table-column>
          <!-- 浏览次数 -->
          <el-table-column  align="center" label="浏览次数">
            <template slot-scope="scope">
             <p>{{scope.row.stat.tel_quantity || 0}}</p>
            </template>
          </el-table-column>
        </el-table>

         <div style="float: right; margin-top: 10px; margin-bottom: 10px">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPages"
            background
            layout="total, prev, pager, next, jumper"
            :total="8">
          </el-pagination>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs"
export default {
  data(){
    return {
       url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg ',
        tableData: [],
        dialogVisible: false,//客户列表弹框
        currentPages: 0,
        query: {//文章数据查询条件
          rid:'',
          startTime:'',
          endTime:'',
          pageNumber:8,
          pageIndex:1,
          name:'',
        },
        detailsQuery: {//客户数据搜索条件body
          size: 1000,
          after: '',
        },
        total_rows:0,//员工列表total
        //员工信息
        staffInfo:{
          headImg:'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          name:'销售名称',
          position:"职位名称",
          id:'',
        },
        customerList:[],//客户列表

    }
  },
  created() {
    var nowTime = dayjs();
    console.log(nowTime);
    this.query.rid = this.$route.query.id;
    this.query.startTime = this.$route.query.startTime;
    this.query.endTime = this.$route.query.endTime;
  },
  mounted(){
  this.getList(0);
  },
  methods: {
    handleCurrentChange(val){
      console.log(val);
    },
    async  getList(page) {
      this.query.pageIndex = page || 0;
      if(this.query.name == '') {
        delete this.query.name;
      }else{
        this.query.name = this.query.name.trim();
      }
      let res = await this.$ZGManager.airticleShareMember(this.query)
      console.log(res);
      if(res.status !==200) return
      this.tableData = res.data.list; 
      this.total_rows = res.data.total_rows;
    },
    //点击查看客户数据
    async ViewCustomer(row) {
      console.log(row);
      this.dialogVisible=true;
      
      this.staffInfo.id = row.id;
      // 获取员工信息
      let res  = await this.$ZGManager.getTeamMemberInfo(this.staffInfo.id );
      console.log("客户信息",res);
      if(res.status !=200) return;
      this.staffInfo.headImg = res.data.headImg;
      this.staffInfo.name = res.data.name;
      this.staffInfo.position = res.data.position;
      this.getCustomerList();
    },
    //获取客户数据列表
    async getCustomerList() {
      let Params = {
        xid:this.staffInfo.id,
        rt:'article',
        status:2,
      }
      let res = await this.$ZGManager.statCustomerList(Params,this.detailsQuery)
      console.log(res);
      this.customerList =res.data.list;
    }
 
  }
}
</script>

<style lang="scss" scoped>
.container{
  background-color: #fff;
  padding: 15px 10px;
  overflow: hidden;
}
.header{
  position: relative;
}
.header-item{
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}
// .dialog{
  // height: 400px;
// }
.share-dialog ::v-deep .el-dialog{
  overflow: auto;
  max-height: 90%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0!important;
}
.content {
  padding: 10px;
  background-color: #fff;
  color: #606266;
  font-weight: 400;
  margin-bottom: 10px;
  font-size: 14px;
  position: relative;
  i {
    color:  #c0c4cc;
  }
  .fenge {
    display: inline-block;
    height: 10px;
    margin-left: 10px;
    margin-right: 10px;
    background-color:  #c0c4cc;
    width: 1px;
  }
  .content-item {
    position: absolute;
    top: 25px;
    left: 90px;
  }
}
.heard_img {
  width: 70px;
  height: 70px;
  position: relative;
  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    vertical-align:middle;
  }
  span {
    position: absolute;
    right: -100px;
    display: inline-block;
    width: 90px;
    text-align: center;
  }
  .salename {
      top: 10px;
  }
  .position {
    top: 40px;
  }
}
</style>
